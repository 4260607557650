export default [
  {
    path: '/cariKartlar',
    name: 'cariKartlar',
    component: () => import('@/views/finans/cariList.vue'),
  }, {
    path: '/vardiyaOzet/:id',
    name: 'vardiyaOzet',
    component: () => import('@/views/finans/vardiyaOzet.vue'),
  }, {
    path: '/vardiyaList',
    name: 'vardiyaList',
    component: () => import('@/views/finans/vardiyaList.vue'),
  }, {
    path: '/giderKayitlari/:id',
    name: 'giderKayitlari',
    component: () => import('@/views/finans/giderKayitlari.vue'),
  }, {
    path: '/konaklamaKayitlari/:id',
    name: 'konaklamaKayitlari',
    component: () => import('@/views/finans/konaklamaKayitlari.vue'),
  }, {
    path: '/gelirKayitlari/:id',
    name: 'gelirKayitlari',
    component: () => import('@/views/finans/detayliGelirList.vue'),
  },
]
