import axios from 'axios'
import store from '@/store'

export default {
  state: {
    rezervasyonNo: '',
    rezervasyonBilgileri: [],
    rezervasyonKonukListesi: [],
    rezervasyonOdaListesi: [],
    odaListesi: [],
    hizliRez: [],
  },
  mutations: {
    hizliRezSave(state, list) {
      state.hizliRez = list
    },
    clearHizliRez(state) {
      state.hizliRez = []
    },
    setToken(state, token) {
      state.userToken = token
      localStorage.setItem('raporUserToken', token)
    },
    setRezervasyonBilgileri(state, resertInfo) {
      state.rezervasyonBilgileri = resertInfo
    },
    setrezervasyonKonukListesi(state, resertKonuk) {
      state.rezervasyonKonukListesi = resertKonuk
    },
    setRezervasyonOdaListesi(state, resertOda) {
      state.rezervasyonOdaListesi = resertOda
    },
    konukKontrolResult(state, result) {
      state.konukKontrolResult = result
    },
    setOdaListesi(state, odaList) {
      state.odaListesi = odaList
    },
    clearRezervasyon(state) {
      state.rezervasyonBilgileri = []
      state.rezervasyonKonukListesi = []
      state.rezervasyonOdaListesi = []
      state.odaListesi = []
    },
    clearYeniRez(state) {
      state.konukKontrolResult = []
    },
  },
  getters: {
    odalar(state) {
      if (state.hizliRez.odaListe) {
        return state.hizliRez.odaListe
      }
    },
    konuklar(state) {
      if (state.hizliRez.konukListe) {
        return state.hizliRez.konukListe
      }
    },
    ay(state) {
      if (state.hizliRez.rapor) {
        return state.hizliRez.rapor.ay == '01' ? 'Ocak' : '' || state.hizliRez.rapor.ay == '02' ? 'Şubat' : '' || state.hizliRez.rapor.ay == '03' ? 'Mart' : '' || state.hizliRez.rapor.ay == '04' ? 'Nisan' : '' || state.hizliRez.rapor.ay == '05' ? 'Mayıs' : '' || state.hizliRez.rapor.ay == '06' ? 'Haziran' : '' || state.hizliRez.rapor.ay == '07' ? 'Temmuz' : '' || state.hizliRez.rapor.ay == '08' ? 'Ağustos' : '' || state.hizliRez.rapor.ay == '09' ? 'Eylül' : '' || state.hizliRez.rapor.ay == '10' ? 'Ekim' : '' || state.hizliRez.rapor.ay == '11' ? 'Kasım' : '' || state.hizliRez.rapor.ay == '12' ? 'Aralık' : ''
      }
    },
    yil(state) {
      if (state.hizliRez.rapor) {
        return state.hizliRez.rapor.yil
      }
    },
    aySonuRez(state) {
      if (state.hizliRez.rapor) {
        const arr = Array.from({ length: state.hizliRez.rapor.aySonu }, (_, index) => index + 1)
        return arr
      }
    },
    getRezList(state) {
      if (state.rezervasyonKonukListesi) {
        return state.rezervasyonKonukListesi
      }
    },
    rezOdaListReturn(state) {
      if (state.rezervasyonOdaListesi) {
        return state.rezervasyonOdaListesi
      }
    },
    konukKontrol(state) {
      if (state.konukKontrolResult) {
        return state.konukKontrolResult
      }
    },
    rezOdaListReturn(state) {
      if (state.odaListesi) {
        return state.odaListesi
      }
    },
  },
  actions: {
    odaRaporYear({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'rapor',
        methodName: 'odaRaporu',
        yil: authData.year,
        ay: authData.month,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          commit('hizliRezSave', res)
        },
        dataType: 'json',
      })
    },
    odaRaporMonth({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'odaRaporu',
        yil: authData.year,
        ay: authData.month == 'Ocak' ? '01' : '' || authData.month == 'Şubat' ? '02' : '' || authData.month == 'Mart' ? '03' : '' || authData.month == 'Nisan' ? '04' : '' || authData.month == 'Mayıs' ? '05' : '' || authData.month == 'Mayıs' ? '05' : '' || authData.month == 'Haziran' ? '06' : '' || authData.month == 'Temmuz' ? '07' : '' || authData.month == 'Ağustos' ? '08' : '' || authData.month == 'Eylül' ? '09' : '' || authData.month == 'Ekim' ? '10' : '' || authData.month == 'Kasım' ? '11' : '' || authData.month == 'Aralık' ? '12' : '',
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          commit('hizliRezSave', res)
        },
        dataType: 'json',
      })
    },
    odaRapor({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'rapor',
        methodName: 'odaRaporu',
        yil: authData.year,
        ay: authData.month,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('hizliRezSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    guestSorgu({
      commit,
      state,
    }, authData) {
      const data = {
        crm_token: state.userToken || localStorage.getItem('raporUserToken'),
        serviceName: 'otel',
        methodName: 'guestSorgu',
        term: authData.term,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    yeniRezSave({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'yeniRezervasyon',
        islem: 'confirm',
        odaKodu: authData.odaKodu,
        girisTarih: authData.girisTarih,
        cikisTarih: authData.cikisTarih,
        konukSayisi: authData.konukSayisi,
        odaKapatma: authData.odaKapatma == true ? 1 : false,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: authData,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    girisKontrol({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'yeniRezervasyon',
        islem: 'rezervasyonKontrol',
        odaKodu: authData.odaKodu,
        girisTarih: authData.girisTarih,
        cikisTarih: authData.cikisTarih,
        konukSayisi: authData.konukSayisi,
        odaKapatma: authData.odaKapatma == true ? 1 : 0,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('konukKontrolResult', res.result)
              resolve(res)
            } else {
              commit('konukKontrolResult', res.result)
              reject(res)
            }
          },
          error: function (res) {
            commit('konukKontrolResult', res.result)
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    odaListGet({
      commit,
      state
    }) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            commit('setToken', res.userToken)
            commit('setOdaListesi', res.data.odaListesi)
            resolve(res)
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    backCheckOut({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'backCheckOut',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    onRezCheckIn({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'checkIn',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    iadeliCheckOut({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'checkOut',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
        iade: true,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    direkCheckOut({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'checkOut',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    odaMessage({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'notGuncelle',
        rezervasyonNo: authData.rezervasyonNo,
        aciklama: authData.aciklama,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            commit('setToken', res.userToken)
            resolve(res)
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    odaStatusChange({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        rezervasyonNo: authData.rezervasyonNo,
        odaKapatmaSet: authData.odaKapatmaSet == true ? 1 : 0,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    toplamFiyatUpdate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'toplamUcretGuncelle',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
        toplamUcret: authData.toplamUcret,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    fiyatUpdate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'fiyatGuncelle',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
        fiyat: authData.fiyat,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    cikisTarihUpdate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
        islem: 'hguestCikisGuncelle',
        cikisTarih: authData.cikisTarih,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    girisTarihUpdate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
        islem: 'hguestGirisGuncelle',
        girisTarih: authData.girisTarih,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    odaKapatma({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        rezervasyonNo: authData.rezervasyonNo,
        odaKapatmaSet: authData.value == true ? 1 : 0,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    yeniKonuk({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'konukKayit',
        rezervasyonNo: authData.rezervasyonNo,
        adi: authData.adi,
        soyAdi: authData.soyAdi,
        tcKimlik: authData.tcKimlik,
        telefon: authData.telefon,
        cinsiyet: authData.cinsiyet == 'Kadın' ? 2 : 1,
        onRezervasyon:
          authData.onRezervasyon == 'Normal'
            ? 0
            : '' || authData.onRezervasyon == 'Rezervasyon'
              ? 1
              : '',
        fiyat: authData.fiyat,
        girisTarih: authData.girisTarih,
        cikisTarih: authData.cikisTarih,
        tarife: authData.tarife,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    konukKayit({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'konukKayit',
        rezervasyonNo: authData.rezervasyonNo,
        adi: authData.adi,
        soyAdi: authData.soyAdi,
        tcKimlik: authData.tcKimlik,
        telefon: authData.telefon,
        cinsiyet: authData.cinsiyet == 'Kadın' ? 2 : 1,
        onRezervasyon:
          authData.onRezervasyon == 'Normal'
            ? 0
            : '' || authData.onRezervasyon == 'Rezervasyon'
              ? 1
              : '',
        fiyat: authData.fiyat,
        girisTarih: authData.girisTarih,
        cikisTarih: authData.cikisTarih,
        tarife: authData.tarife,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    kaydiSil({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'mukerrerKonaklama',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    tahsilatYapBaskaHesap({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'tahsilatYap',
        rezervasyonNo: authData.rezervasyonNo,
        odemeTuru: 'kredi',
        baskaHesap: true,
        tutar: authData.tutar,
        tcKimlik: authData.tcKimlik,
        fisNo: authData.fisNo,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    tahsilatYap({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'tahsilatYap',
        rezervasyonNo: authData.rezervasyonNo,
        odemeTuru: authData.odemeTuru.tur,
        tutar: authData.tutar,
        fisNo: authData.fisNo,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    mukerrerTahsilat({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'mukerrerTahsilat',
        rezervasyonNo: authData.rezervasyonNo,
        tahsilatID: authData.tahsilatID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    konukChange({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'konukDegistir',
        rezervasyonNo: authData.rezervasyonNo,
        adi: authData.adi,
        soyAdi: authData.soyAdi,
        tcKimlik: authData.tcKimlik,
        cinsiyet: authData.cinsiyet == 'Erkek' ? 1 : 2,
        tarife: authData.tarife,
        telefon: authData.telefon,
        otoPlaka: authData.otoPlaka,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    konukUpdate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'konukGuncelle',
        rezervasyonNo: authData.rezervasyonNo,
        adi: authData.adi,
        soyAdi: authData.soyAdi,
        tcKimlik: authData.tcKimlik,
        cinsiyet: authData.cinsiyet == 'Erkek' ? 1 : 2,
        tarife: authData.tarife,
        telefon: authData.telefon,
        otoPlaka: authData.otoPlaka,
        hguestID: authData.hguestID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    odaChange({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'odaDegis',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.chooseRezervasyon.hguestID,
        odaKodu: authData.odaSelect.odaKodu,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
              commit(
                'setrezervasyonKonukListesi',
                res.data.rezervasyonKonukListesi
              )
              commit(
                'setRezervasyonOdaListesi',
                res.data.rezervasyonOdaListesi
              )
              commit('setBildirim', res.bildirim)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    dragOdaChange({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        islem: 'odaDegis',
        rezervasyonNo: authData.rezervasyonNo,
        hguestID: authData.guestID,
        odaKodu: authData.odaKodu,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data: data,
          success: function (res) {
            if (res.result.status == 200) {
              commit('setToken', res.userToken)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error: function (res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    async rezervasyonGo({
      commit,
      state
    }, authData) {
      const data = {
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        crm_token: state.userToken || localStorage.getItem('raporUserToken'),
        rezervasyonNo: authData,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success: function (res) {
            resolve(res)
            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
            commit(
              'setrezervasyonKonukListesi',
              res.data.rezervasyonKonukListesi,
            )
            commit('setOdaListesi', res.data.odaListesi)
            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi)
            commit('setToken', res.userToken)
          },
          error: function (err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
