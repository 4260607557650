export default [
  {
    path: '/',
    name: 'dash',
    component: () => import('@/views/kurumList/dash.vue'),
  },
  {
    path: '/kurumDetay',
    name: 'kurumDetay',
    component: () => import('@/views/kurumDetay/kurumDash.vue'),
  },
]
