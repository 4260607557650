import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import pages from './routes/pages'
import dash from './routes/oys'
import auth from './routes/auth'
import finans from './routes/finans'
import kurumInfo from './routes/kurumInfo'
import rapor from './routes/rapor'
import services from './routes/services'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [{
    path: '/',
    redirect: { name: 'dash' },
  }, ...services, ...rapor, ...kurumInfo, ...finans, ...auth, ...dash, ...pages, {
    path: '*',
    redirect: 'error-404',
  },],
})

// router.beforeEach(async (to, from, next) => {
//   const isLoggedIn = await store.getters.isAuthenticated
//   if (to.path === '/login' || to.path === '/expariedLogin') {
//     if (isLoggedIn) {
//       return next(false)
//     }
//     if (!isLoggedIn) {
//       try {
//         await store.dispatch('authControl')
//         return next()
//       } catch (error) {
//         return next({ name: 'login' })
//       }
//     }
//   }
//   return next()
// })

router.beforeEach((to, _, next) => {
  const isLoggedInStore = store.getters.isAuthenticated
  // const isLoggedInStore = localStorage.getItem('raporUserToken');
  if (to.path !== '/expariedLogin') {
    if (to.path !== '/login') {
      if (!isLoggedInStore) {
        next({ name: 'login' })
      } else {
        next()
      }
    }
  }

  if (to.path === '/login' && isLoggedInStore) {
    return false
  } else {
    if (to.path === '/expariedLogin' && isLoggedInStore) {
      return false
    } else {
      next()
    }
    next()
  }

  // Authentication var ise
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
