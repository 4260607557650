// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default {
  state: {
    odaListesi: [],
    odaListesiParams: [],
    userList: [],
  },
  mutations: {
    userListAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? (state.userList = action.userList)
        : (state.userList = [])
    },
    odaListesiAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? (state.odaListesi = action.odaListesi)
        : (state.odaListesi = [])
    },
    odaListesiParamsAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? (state.odaListesiParams = action.params)
        : (state.odaListesiParams = [])
    },
  },
  getters: {
    getUserList(state) {
      if (state.userList) {
        return state.userList
      }
    },
    getOdaListesi(state) {
      if (state.odaListesi) {
        return state.odaListesi
      }
    },
    odaParams: state => COLOR_CODE => state.odaListesiParams.odaRenk[COLOR_CODE],
  },
  actions: {
    userList({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'kullaniciListesi',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
            commit('userListAction', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    odaList({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'odaListesi',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
            commit('odaListesiAction', res)
            commit('odaListesiParamsAction', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
