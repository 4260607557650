// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default {
  state: {
    cariList: [],
    vardiyaOzeti: [],
    vardiyaListesi: [],
    vardiyaListesiCount: 0,
    giderListesi: [],
    konaklamaListesi: [],
    gelirListesi: [],
    detayliGelir: [],
    detayliGider: [],
    detayliTahsilat: [],
  },
  mutations: {
    vardiyaListAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? (state.vardiyaListesi = action)
        : (state.vardiyaListesi = [])
    },
    vardiyaListCount(state, count) {
      state.vardiyaListesiCount = count
    },
    cariListAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.cariList = action.cariListe))
        : ((state.cariList = []))
    },
    vardiyaAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.vardiyaOzeti = action))
        : ((state.vardiyaOzeti = []))
    },
    giderAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.giderListesi = action.giderKayitlari.gider))
        : ((state.giderListesi = []))
    },
    gelirAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.gelirListesi = action))
        : ((state.gelirListesi = []))
    },
    konaklamaAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.konaklamaListesi = action.konaklamaKayitlari))
        : ((state.konaklamaListesi = []))
    },
    gelirKayitlariAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.detayliGelir = action))
        : ((state.detayliGelir = []))
    },
    giderKayitlariAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.detayliGider = action))
        : ((state.detayliGider = []))
    },
    tahsilatKayitlariAction(state, action) {
      // eslint-disable-next-line no-unused-expressions
      action !== 'remove'
        ? ((state.detayliGider = action))
        : ((state.detayliGider = []))
    },
  },

  getters: {
    getKonaklama(state) {
      if (state.konaklamaListesi) {
        return state.konaklamaListesi
      }
    },
    getGelir(state) {
      if (state.gelirListesi) {
        return state.gelirListesi
      }
    },
    getGider(state) {
      if (state.giderListesi) {
        return state.giderListesi
      }
    },
    getKurumName(state) {
      if (state.vardiyaOzeti.firmaBilgileri) {
        return state.vardiyaOzeti.firmaBilgileri.firma.unvan
      }
    },
    getVardiya(state) {
      return state.vardiyaOzeti
    },
    getVardiyaList(state) {
      return {
        vardiyaListesi: state.vardiyaListesi,
        vardiyaListCount: state.vardiyaListesiCount,
      }
    },
    getCariListe(state) {
      return state.cariList
    },
    getDetayliList(state) {
      return state.detayliGelir
    },
    getDetayliGider(state) {
      return state.detayliGider
    },
    getDetayliTahsilat(state) {
      return state.detayliTahsilat
    },
  },
  actions: {
    gelirKayitlari({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'vardiya',
        methodName: 'gelirKayitlari',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        [authData !== 0 ? 'vardiyaID' : '']: authData !== 0 ? authData : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
            commit('gelirAction', res)
            commit('gelirKayitlariAction', res.gelirKayitlari.gelir)
            commit('giderKayitlariAction', res.giderKayitlari.gider)
            commit('giderKayitlariAction', res.giderKayitlari.gider)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaKayitlari({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'vardiya',
        methodName: 'konaklamaKayitlari',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        [authData !== 0 ? 'vardiyaID' : '']: authData !== 0 ? authData : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaAction', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    giderKayitlari({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'vardiya',
        methodName: 'giderKayitlari',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        [authData !== 0 ? 'vardiyaID' : '']: authData !== 0 ? authData : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
            commit('giderAction', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    vardiyaListesi({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'vardiya',
        methodName: 'vardiyaListesi',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        Sayfala: authData.sayfala !== false ? authData.sayfala : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
            commit('vardiyaListAction', res.vardiyaListesi)
            commit('vardiyaListCount', res.toplamKayit)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    vardiyaOzeti({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'vardiya',
        methodName: 'vardiyaOzeti',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        [authData !== 0 ? 'vardiyaID' : '']: authData !== 0 ? authData : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
            commit('vardiyaAction', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    cariKart({
      commit,
    }) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'carikart',
        methodName: 'cariListe',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
            commit('cariListAction', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
