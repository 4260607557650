export default [
  {
    path: '/odaListesi',
    name: 'odaListesi',
    component: () => import('@/views/kurumInfo/odaList.vue'),
  }, {
    path: '/kullaniciListesi',
    name: 'kullaniciListesi',
    component: () => import('@/views/kurumInfo/userList.vue'),
  },
]
