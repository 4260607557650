// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default {
  state: {
    bekleyenTah: [],
    konaklamaList: [],
    checkOutList: [],
    houseKeepingList: [],
    rezervasyonList: [],
    onlineRezList: [],
    aylikRapor: [],
    yillikRapor: [],
    tekil: [],
    odaRapor: [],
    konaklamaGecmisi: [],
    vardiyaOzeti: [],
    vardiyaOzetiCiro: [],
    vardiyaList: [],
    gelirKayitlari: [],
    konaklamaKayitlari: [],
    giderKayitlari: [],
    posFatura: [],
    siparisDetay: [],
    bankaPos: [],
    vardiyaDate: [],
    raporGunDetay: [],
    onlineRezListActive: [],
    onlineRezListGelecek: [],
    onlineRezListFiltre: [],
    konaklamaListActive: [],
    konaklamaListPasif: [],
    konaklamaListActiveFiltre: [],
    konaklamaListPasifFiltre: [],
    kasaRaporu: [],
    kasaGelirListesi: [],
    kasaGelirSayac: '',
    kasaGiderListesi: [],
    kasaGiderSayac: '',
    kasaHavaleListesi: [],
    kasaHavaleSayac: '',
    kasaKonukListesi: [],
    kasaKonukSayac: '',
    kasaOnlineListesi: [],
    kasaOnlineSayac: '',
  },
  mutations: {
    kasaSave(state, rez) {
      state.kasaRaporu = rez
    },
    kasaGelirSave(state, rez) {
      state.kasaGelirListesi = rez.gelirListesi
      state.kasaGelirSayac = rez.gelirListesiSayac
    },
    kasaGiderSave(state, rez) {
      state.kasaGiderListesi = rez.giderListesi
      state.kasaGiderSayac = rez.giderListesiSayac
    },
    kasaHavaleSave(state, rez) {
      state.kasaHavaleListesi = rez.havaleListesi
      state.kasaHavaleSayac = rez.havaleListesiSayac
    },
    kasaKonukSave(state, rez) {
      state.kasaKonukListesi = rez.konukListesi
      state.kasaKonukSayac = rez.konukListesiSayac
    },
    kasaOnlineSave(state, rez) {
      state.kasaOnlineListesi = rez.onlineListesi
      state.kasaOnlineSayac = rez.onlineListesiSayac
    },
    konaklamaActiveListSave(state, rez) {
      state.konaklamaListActive = rez.aktifKonuklar
    },
    konaklamaPasifListSave(state, rez) {
      state.konaklamaListPasif = rez.pasifKonuklar
    },
    konaklamaActiveFiltreSave(state, rez) {
      state.konaklamaListActiveFiltre = rez.aktifKonuklar
    },
    konaklamaPasifFiltreSave(state, rez) {
      state.konaklamaListPasifFiltre = rez.pasifKonuklar
    },
    onlineAktifListSave(state, rez) {
      state.onlineRezListActive = rez.aktifKonuklar
    },
    onlineGelecekListSave(state, rez) {
      state.onlineRezListGelecek = rez.gelecekKonuklar
    },
    onlineFiltreListSave(state, rez) {
      state.onlineRezListFiltre = rez.arsiv
    },
    onlineAktifList(state, rez) {
      state.onlineRezList = rez
    },
    raporGunDetaySave(state, list) {
      state.raporGunDetay = list
    },
    vardiyaDateSave(state, baslangic) {
      state.vardiyaDate = baslangic
    },
    bankaPosSave(state, list) {
      state.bankaPos = list
    },
    clearBankaPos(state) {
      state.bankaPos = []
    },
    siparisDetaySave(state, list) {
      state.siparisDetay = list
    },
    posFaturaSave(state, list) {
      state.posFatura = list.liste
    },
    giderKayitlariSave(state, list) {
      state.giderKayitlari = list
    },
    konaklamaKayitlariSave(state, list) {
      state.konaklamaKayitlari = list
    },
    gelirKayitlariSave(state, list) {
      state.gelirKayitlari = list
    },
    vardiyaListeSave(state, list) {
      state.vardiyaList = list
    },
    vardiyaOzetiSave(state, list) {
      state.vardiyaOzeti = list
      state.vardiyaOzetiCiro = list.gelirKayitlari
    },
    clearSiparisDetay(state) {
      state.siparisDetay = []
    },
    clearPosFatura(state) {
      state.posFatura = []
    },
    clearGiderKayitlari(state) {
      state.giderKayitlari = []
    },
    clearKonaklamaKayitlari(state) {
      state.konaklamaKayitlari = []
    },
    clearVardiyaOzeti(state) {
      state.vardiyaOzeti = []
      state.vardiyaOzetiCiro = []
    },
    konaklamaGecmisiSave(state, list) {
      state.konaklamaGecmisi = list
    },
    odaRaporSave(state, list) {
      state.odaRapor = list
    },
    clearGelirKayitlari(state) {
      state.clearGelirKayitlari = []
    },
    clearVardiyaList(state) {
      state.vardiyaList = []
    },
    clearOdaRapor(state) {
      state.odaRapor = []
    },
    yillikRaporSave(state, list) {
      state.yillikRapor = list
    },
    aylikTekilSave(state, list) {
      state.tekil = list
    },
    aylikRaporSave(state, list) {
      state.aylikRapor = list
    },
    rezervasyonListSave(state, rez) {
      state.rezervasyonList = rez
    },
    konaklamaListSave(state, kon) {
      state.konaklamaList = kon
    },
    houseKeepingSave(state, list) {
      state.houseKeepingList = list
    },
    checkOutSave(state, list) {
      state.checkOutList = list
    },
    tahSave(state, tah) {
      state.bekleyenTah = tah
    },
    clearHouseKeepingList(state) {
      state.houseKeepingList = []
    },
    clearkonaklamaGecmisi(state) {
      state.konaklamaGecmisi = []
    },
    clearCheckOutList(state) {
      state.checkOutList = []
    },
    clearBekleyenTah(state) {
      state.bekleyenTah = []
    },
    clearKonaklamaList(state) {
      state.konaklamaList = []
    },
    clearRezervasyonList(state) {
      state.rezervasyonList = []
    },
    clearOnlineRezList(state) {
      state.onlineRezList = []
    },
    clearAylikRapor(state) {
      state.aylikRapor = []
    },
    clearYillikRapor(state) {
      state.yillikRapor = []
    },
  },
  getters: {
    kasaRaporu(state) {
      if (state.kasaRaporu) {
        return state.kasaRaporu
      }
      return false
    },
    kasaToplamGelir(state) {
      if (state.kasaRaporu.toplamGelir) {
        return state.kasaRaporu.toplamGelir
      }
      return false
    },
    kasaToplamHavale(state) {
      if (state.kasaRaporu.toplamHavale) {
        return state.kasaRaporu.toplamHavale
      }
    },
    kasaToplamGider(state) {
      if (state.kasaRaporu.toplamGider) {
        return state.kasaRaporu.toplamGider
      }
    },
    kasaToplamKonaklama(state) {
      if (state.kasaRaporu.konukListesiSayac) {
        return state.kasaRaporu.konukListesiSayac
      }
    },
    kasaToplamOnline(state) {
      if (state.kasaRaporu.onlineListesiSayac) {
        return state.kasaRaporu.onlineListesiSayac
      }
    },
    kasaGelirListesi(state) {
      if (state.kasaGelirListesi) {
        return state.kasaGelirListesi
      }
    },
    kasaGelirSayac(state) {
      if (state.kasaGelirSayac) {
        return state.kasaGelirSayac
      }
    },
    kasaGiderListesi(state) {
      if (state.kasaGiderListesi) {
        return state.kasaGiderListesi
      }
    },
    kasaGiderSayac(state) {
      if (state.kasaGiderSayac) {
        return state.kasaGiderSayac
      }
    },
    kasaHavaleListesi(state) {
      if (state.kasaHavaleListesi) {
        return state.kasaHavaleListesi
      }
    },
    kasaHavaleSayac(state) {
      if (state.kasaHavaleSayac) {
        return state.kasaHavaleSayac
      }
    },
    kasaKonukListesi(state) {
      if (state.kasaKonukListesi) {
        return state.kasaKonukListesi
      }
    },
    kasaKonukSayac(state) {
      if (state.kasaKonukSayac) {
        return state.kasaKonukSayac
      }
    },
    kasaOnlineListesi(state) {
      if (state.kasaOnlineListesi) {
        return state.kasaOnlineListesi
      }
    },
    kasaOnlineSayac(state) {
      if (state.kasaOnlineSayac) {
        return state.kasaOnlineSayac
      }
    },
    konaklamaListActive(state) {
      if (state.konaklamaListActive) {
        return state.konaklamaListActive
      }
    },
    konaklamaListPasif(state) {
      if (state.konaklamaListPasif) {
        return state.konaklamaListPasif
      }
    },
    konaklamaListActiveFiltre(state) {
      if (state.konaklamaListActiveFiltre) {
        return state.konaklamaListActiveFiltre
      }
    },
    konaklamaListPasifFiltre(state) {
      if (state.konaklamaListPasifFiltre) {
        return state.konaklamaListPasifFiltre
      }
    },
    onlineRezActiveList(state) {
      if (state.onlineRezListActive) {
        return state.onlineRezListActive
      }
    },
    onlineRezGelecekList(state) {
      if (state.onlineRezListGelecek) {
        return state.onlineRezListGelecek
      }
    },
    onlineRezFiltreList(state) {
      if (state.onlineRezListFiltre) {
        return state.onlineRezListFiltre
      }
    },
    posFaturaReturn(state) {
      if (state.posFatura) {
        return state.posFatura
      }
    },
    siparisDetayReturn(state) {
      if (state.siparisDetay) {
        return state.siparisDetay
      }
    },
    konaklamaListReturn(state) {
      if (state.konaklamaList) {
        return state.konaklamaList
      }
    },
    raporGunDetayReturn(state) {
      if (state.raporGunDetay) {
        return state.raporGunDetay
      }
    },
    yillikRaporReturn(state) {
      if (state.yillikRapor) {
        return state.yillikRapor
      }
    },
    aylikRaporReturn(state) {
      if (state.aylikRapor) {
        return state.aylikRapor
      }
    },
    raporGunDetayReturn(state) {
      if (state.raporGunDetay) {
        return state.raporGunDetay
      }
    },
    vardiyaDateReturn(state) {
      if (state.vardiyaDate) {
        return state.vardiyaDate
      }
    },
    vardiyaListesiReturn(state) {
      if (state.vardiyaList) {
        return state.vardiyaList.vardiyaListesi
      }
    },
    vardiyaListesiReturnKayit(state) {
      if (state.vardiyaList) {
        return state.vardiyaList.toplamKayit
      }
    },
    odaKonukListesi(state) {
      if (state.odaRapor) {
        return state.odaRapor.konukListe
      }
    },
    myOdaListesiReturn(state) {
      if (state.odaRapor) {
        return state.odaRapor.odaListe
      }
    },
    bankaPosReturn(state) {
      if (state.bankaPos) {
        return state.bankaPos
      }
    },
    aktifKonaklamaListCount(state) {
      if (state.konaklamaList.aktifKonuklar) {
        return Object.entries(state.konaklamaList.aktifKonuklar).length
      }
    },
    pasifKonaklamaListCount(state) {
      if (state.konaklamaList.pasifKonuklar) {
        return Object.entries(state.konaklamaList.pasifKonuklar).length
      }
    },
    houseKeepingListReturn(state) {
      if (state.houseKeepingList) {
        return state.houseKeepingList
      }
    },
    rezervasyonListe(state) {
      if (state.rezervasyonList.list) {
        return state.rezervasyonList.list
      }
    },
    rezervasyonListeCount(state) {
      if (state.rezervasyonList.list) {
        return state.rezervasyonList.list.length
      }
    },
    kartSahibi(state) {
      if (state.siparisDetay.paymentDetay.ccName) {
        return state.siparisDetay.paymentDetay.ccName
      }
    },
    masked(state) {
      return state.siparisDetay.paymentDetay.maskedPan
    },
    posFaturaGetir(state) {
      return state.posFatura.liste
    },
    tahListReturn(state) {
      if (state.vardiyaOzeti) {
        if (state.vardiyaOzeti.tahsilatKayitlari) {
          return state.vardiyaOzeti.tahsilatKayitlari.tahsilatList
        }
      }
    },
    gelirKayilariReturn(state) {
      if (state.vardiyaOzeti.gelirKayitlari) {
        return state.vardiyaOzeti.gelirKayitlari.gelir
      }
    },
    giderKayilariReturn(state) {
      if (state.vardiyaOzeti.giderKayitlari) {
        return state.vardiyaOzeti.giderKayitlari.gider
      }
    },
    vardiyaGideri(state) {
      if (state.vardiyaOzeti.giderKayitlari) {
        return state.vardiyaOzeti.giderKayitlari.giderler
      }
    },
    toplamCiro(state) {
      return state.vardiyaOzetiCiro.toplamCiro
    },
    krediCiro(state) {
      return state.vardiyaOzetiCiro.krediCiro
    },
    krediTahReturn(state) {
      if (state.vardiyaOzeti.tahsilatKayitlari) {
        return state.vardiyaOzeti.tahsilatKayitlari.tahsilatlar
      }
    },
    giderKayitlariReturn(state) {
      if (state.giderKayitlari.giderKayitlari) {
        return state.giderKayitlari.giderKayitlari.gider
      }
    },
    detayliGelirKayitlariReturn(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.gelir
      }
    },
    detayliGelirKayitlariNakitCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.nakitCiro
      }
    },
    detayliGelirKayitlariKKrediKartiCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.kkartiCiro
      }
    },
    detayliGelirKayitlariHavaleCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.havaleCiro
      }
    },
    detayliGelirKayitlariKrediCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.krediCiro
      }
    },
    detayliGelirKayitlarToplamCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.toplamCiro
      }
    },
    detayliGelirKayitlarTahsilatListesi(state) {
      if (state.gelirKayitlari.tahsilatKayitlari) {
        return state.gelirKayitlari.tahsilatKayitlari.tahsilatList
      }
    },
    detayliGelirKayitlarTahsilatNakitMoney(state) {
      if (state.gelirKayitlari.tahsilatKayitlari) {
        return state.gelirKayitlari.tahsilatKayitlari.tahsilatNakit
      }
    },
    detayliGelirKayitlarTahsilatKrediKartiMoney(state) {
      if (state.gelirKayitlari.tahsilatKayitlari) {
        return state.gelirKayitlari.tahsilatKayitlari.tahsilatKrediKarti
      }
    },
    detayliGelirKayitlarTahsilatTahsilatMoney(state) {
      if (state.gelirKayitlari.tahsilatKayitlari) {
        return state.gelirKayitlari.tahsilatKayitlari.tahsilatHavale
      }
    },
    detayliGelirKayitlarTahsilatDefaultTah(state) {
      if (state.gelirKayitlari.tahsilatKayitlari) {
        return state.gelirKayitlari.tahsilatKayitlari.tahsilatlar
      }
    },
    detayliGelirKayitlarInsideGider(state) {
      if (state.gelirKayitlari.giderKayitlari) {
        return state.gelirKayitlari.giderKayitlari.gider
      }
    },
    detayliGelirKayitlarInsideGiderler(state) {
      if (state.gelirKayitlari.giderKayitlari) {
        return state.gelirKayitlari.giderKayitlari.giderler
      }
    },
    detayliGelirKayitlarNakitCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return (state.gelirKayitlari.gelirKayitlari.nakitCiro + state.gelirKayitlari.tahsilatKayitlari.tahsilatNakit - state.gelirKayitlari.giderKayitlari.giderler)
      }
    },
    detayliGelirKayitlarKkartiCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.kkartiCiro
      }
    },
    detayliGelirKayitlarHavaleCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        return state.gelirKayitlari.gelirKayitlari.havaleCiro
      }
    },
    detayliGelirKayitlarTotalCiro(state) {
      if (state.gelirKayitlari.gelirKayitlari) {
        const root = state.gelirKayitlari.gelirKayitlari
        return (root.havaleCiro + root.kkartiCiro + root.nakitCiro + state.gelirKayitlari.tahsilatKayitlari.tahsilatlar - state.gelirKayitlari.giderKayitlari.giderler)
      }
    },
    nakitCiro(state) {
      return state.vardiyaOzetiCiro.nakitCiro
    },
    kKartiCiro(state) {
      return state.vardiyaOzetiCiro.kkartiCiro
    },
    havaleCiro(state) {
      return state.vardiyaOzetiCiro.havaleCiro
    },
    netKasa(state) {
      if (state.vardiyaOzeti.gelirKayitlari) {
        return (state.vardiyaOzeti.gelirKayitlari.nakitCiro - state.vardiyaOzeti.giderKayitlari.giderler)
      }
    },
    konaklamaGet: (state) => (salut, tur) => {
      if (tur == 'Sivil') {
        if (state.yillikRapor[salut]) {
          return state.yillikRapor[salut].liste.sivil
        }
      } else if (tur == 'Uye') {
        if (state.yillikRapor[salut]) {
          return state.yillikRapor[salut].liste.uye
        }
      } else if (tur == 'Kamu') {
        if (state.yillikRapor[salut]) {
          return state.yillikRapor[salut].liste.kamu
        }
      } else if (tur == 'Total') {
        if (state.yillikRapor[salut]) {
          return state.yillikRapor[salut].liste.toplam
        }
      } else {
        if (state.yillikRapor[salut]) {
          return state.yillikRapor[salut].dolulukOrani
        }
      }
    },
    aylikTumRapor(state) {
      return state.aylikRapor.tekilKonukSayilari.uye
    },
    bekleyenTahReturn(state) {
      return state.bekleyenTah.liste
    },
    bekleyenTahReturnSayi(state) {
      if (state.bekleyenTah.liste) {
        return Object.entries(state.bekleyenTah.liste).length
      }
    },
  },
  actions: {
    selectDate({
      commit,
      state
    }, authData) {
      if (authData.dateDefault) {
        commit('kurumDetayAction', 'remove')
        const data = {
          serviceName: 'rapor',
          methodName: 'dashBoard',
          dashboardTarih: authData.dateDefault,
          crm_token: store.state.userToken,
          firmaKodu: localStorage.getItem('raporKurumKodu'),
        }
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('setWorkDay', authData.dateDefault)
            localStorage.setItem('raporCurrentDate', authData.dateDefault)
            commit('setToken', res.userToken)
            commit('kurumDetayAction', res)
          },
          error(err) {
            if (err.status == 503) {
              router.push('/expariedLogin')
            }
          },
          dataType: 'json',
        })
      }
    },
    dateBack({
      commit,
      state
    }, authData) {
      commit('kurumDetayAction', 'remove')
      const today = new Date(localStorage.getItem('raporCurrentDate'))
      const tarih = today.setDate(today.getDate() - 1)
      const gidenTarih = new Date(tarih).toLocaleDateString('en-US')
      const yeniDate = new Date(gidenTarih)
      const yil = yeniDate.getFullYear()
      const ay = String(yeniDate.getMonth() + 1)
        .padStart(2, '0')
      const gun = String(yeniDate.getDate())
        .padStart(2, '0')
      const readyTarih = [yil, ay, gun].join('-')
      const data = {
        serviceName: 'rapor',
        methodName: 'dashBoard',
        dashboardTarih: readyTarih,
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          commit('setWorkDay', readyTarih)
          localStorage.setItem('raporCurrentDate', readyTarih)
          commit('setToken', res.userToken)
          commit('kurumDetayAction', res)
        },
        error(err) {
          if (err.status === 503) {
            router.push('/expariedLogin')
          }
        },
        dataType: 'json',
      })
    },
    dateNext({
      commit,
      state
    }, authData) {
      commit('kurumDetayAction', 'remove')
      // const todayy = new Date()
      // const dd = String(todayy.getDate())
      //   .padStart(2, '0')
      // const mm = String(todayy.getMonth() + 1)
      //   .padStart(2, '0')
      // const yyyy = todayy.getFullYear()
      // const veri = [yyyy, mm, dd].join('-')
      // const today = new Date(veri)
      const today = new Date(localStorage.getItem('raporCurrentDate'))
      const tarih = today.setDate(today.getDate() + 1)
      const gidenTarih = new Date(tarih).toLocaleDateString('en-US')
      const yeniDate = new Date(gidenTarih)
      const yil = yeniDate.getFullYear()
      const ay = String(yeniDate.getMonth() + 1)
        .padStart(2, '0')
      const gun = String(yeniDate.getDate())
        .padStart(2, '0')
      const readyTarih = [yil, ay, gun].join('-')
      const data = {
        serviceName: 'rapor',
        methodName: 'dashBoard',
        dashboardTarih: readyTarih,
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          commit('setWorkDay', readyTarih)
          localStorage.setItem('raporCurrentDate', readyTarih)
          commit('setToken', res.userToken)
          commit('kurumDetayAction', res)
          return false
        },
        error(err) {
          if (err.status === 503) {
            router.push('/expariedLogin')
          }
        },
        dataType: 'json',
      })
    },
    kasaList({
      commit,
      state,
    }, authData) {
      const data = {
        kasaList: 'kasaList',
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'kasaRaporu',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null || authData.end,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('kasaSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    kasaListSayfala({
      commit,
      state
    }, authData) {
      const data = {
        kasaListSayfala: 'kasaListSayfala',
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'kasaRaporuSayfala',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.tablo != null ? 'tablo' : '']: authData.tablo != null ? authData.tablo : '',
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null || authData.end,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('kasaGelirSave', res.rapor)
            commit('kasaHavaleSave', res.rapor)
            commit('kasaGiderSave', res.rapor)
            commit('kasaKonukSave', res.rapor)
            commit('kasaOnlineSave', res.rapor)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    raporGun({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'raporGunDetay',
        tarih: authData.tarih,
        tarife: authData.tarife,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('raporGunDetaySave', res.liste)
          },
          dataType: 'json',
        })
      })
    },
    bankaPos({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'sanalPos',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('bankaPosSave', res)
          },
          dataType: 'json',
        })
      })
    },
    bankaPosFiltre({
      commit,
      state,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'sanalPos',
        filtre: true,
        baslangic: authData.baslangic,
        bitis: authData.bitis,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('bankaPosSave', res)
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    siparisDetay({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'webRezervasyonDetay',
        siparisNo: authData,
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          commit('siparisDetaySave', res)
        },
        dataType: 'json',
      })
    },
    faturaCreate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'sepetKayitlari',
        faturaUnvan: authData.unvan,
        ePosta: authData.mail,
        adi: authData.adi,
        soyAdi: authData.soyAdi,
        vergiDairesi: authData.daire,
        vergiNumarasi: authData.numara,
        faturaAdres: authData.adres,
        faturaIl: authData.il,
        faturaIlce: authData.ilce,
        telefon: authData.telefon,
        siparisNo: authData.siparisNo,
        toplamTutar: authData.tutar,
        faturaOlustur: 'ok',
        konaklamaVergisi: authData.konaklamaVergisi,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.result.status === 200) {
              commit('faturaListSave', res)
              commit('posFaturaSave', res)
              resolve(res)
            } else {
              reject(res)
            }
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    removeGelirRemove({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'vardiya',
        methodName: 'vardiyaOzeti',
        [authData.tur]: authData.val,
        [authData.vardiyaID !== 0 ? 'vardiyaID' : '']: authData.vardiyaID !== 0 ? authData.vardiyaID : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('vardiyaOzetiSave', res)
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    tutarUpdate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'vardiya',
        methodName: 'vardiyaOzeti',
        islem: 'gelirGuncelle',
        gelirID: authData.gelirID,
        eskiFiyat: authData.eskiFiyat,
        departman: authData.departman,
        gelir: authData.gelir,
        hguestID: authData.hguestID,
        vardiyaID: authData.vardiyaID,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('vardiyaOzetiSave', res)
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    async 'pos&Fatura'({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'sepetKayitlariSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('posFaturaSave', res)
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    async 'pos&FaturaFiltre'({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'sepetKayitlariSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        start: authData.start,
        end: authData.end,
        [authData.filtre == true ? 'filtre' : '']: authData.filtre == true ? true : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('posFaturaSave', res)
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    onlineRezListAktive({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'onlineRezervasyonListesiSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('onlineAktifListSave', res)
            commit('onlineFiltreListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    onlineRezListGelecek({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'onlineRezervasyonListesiSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('onlineGelecekListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaListActive({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesiSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaActiveListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaListPasif({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesiSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaPasifListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaListActiveFiltre({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesiSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaActiveFiltreSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaListPasifFiltre({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesiSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end || '',
        [authData.start != null ? 'filtre' : '']: authData.start != null,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaPasifFiltreSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaList({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesiSearch',
        [authData.vardiyaID === 0 ? '' : 'vardiyaID']: authData.vardiyaID === 0 ? '' : authData.vardiyaID,
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.filtre !== false ? 'filtre' : '']: authData.start !== false || authData.end,
        [authData.start !== false ? 'start' : '']: authData.start !== false ? authData.start : '',
        [authData.end !== false ? 'end' : '']: authData.end || '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaActiveListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    giderKayitlari({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'vardiya',
        methodName: 'giderKayitlari',
        [authData == 0 ? '' : 'vardiyaID']: authData == 0 ? '' : authData,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('giderKayitlariSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaKayitlari({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'vardiya',
        methodName: 'konaklamaKayitlari',
        [authData == 0 ? '' : 'vardiyaID']: authData == 0 ? '' : authData,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaKayitlariSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    gelirKayitlari({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'vardiya',
        methodName: 'gelirKayitlari',
        [authData == 0 ? '' : 'vardiyaID']: authData == 0 ? '' : authData,
        // [authData.vardiyaID !== 0 ? 'vardiyaID' : '']: authData.vardiyaID !== 0 ? authData.vardiyaID : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('gelirKayitlariSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    vardiyaList({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'vardiya',
        methodName: 'vardiyaListesi',
        Sayfala: authData.sayfala !== false ? authData.sayfala : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('vardiyaListeSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    vardiyaOzeti({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'vardiya',
        methodName: 'vardiyaOzeti',
        [authData.vardiyaID !== 0 ? 'vardiyaID' : '']: authData.vardiyaID !== 0 ? authData.vardiyaID : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('vardiyaOzetiSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    rezControl({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rezervasyon',
        methodName: 'rezervasyonDetay',
        rezervasyonNo: authData.rezNo,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.data.rezervasyonBilgileri != null) {
              resolve(res)
            } else {
              reject(res)
            }
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaGecmisiIsim({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaGecmisi',
        sorguTuru: 'isim',
        adi: authData.adi,
        soyAdi: authData.soyAdi,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.liste[0] !== undefined) {
              commit('konaklamaGecmisiSave', res)
              resolve(res.liste)
            } else {
              reject(res)
            }
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaGecmisi({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaGecmisi',
        sorguTuru: authData.sorguTuru,
        [authData.sorguTuru == 'tcKimlik' ? 'tcKimlik' : '' || authData.sorguTuru == 'telefon' ? 'telefon' : '']: authData.sorguTuru == 'tcKimlik' ? authData.tcKimlik : '' || authData.sorguTuru == 'telefon' ? authData.telefon : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.liste[0] != undefined) {
              commit('konaklamaGecmisiSave', res)
              resolve(res.liste)
            } else {
              reject(res)
            }
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    odaRapor({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'odaRaporu',
        yil: authData.year,
        ay: authData.month == 'Ocak' ? '01' : '' || authData.month == 'Şubat' ? '02' : '' || authData.month == 'Mart' ? '03' : '' || authData.month == 'Nisan' ? '04' : '' || authData.month == 'Mayıs' ? '05' : '' || authData.month == 'Mayıs' ? '05' : '' || authData.month == 'Haziran' ? '06' : '' || authData.month == 'Temmuz' ? '07' : '' || authData.month == 'Ağustos' ? '08' : '' || authData.month == 'Eylül' ? '09' : '' || authData.month == 'Ekim' ? '10' : '' || authData.month == 'Kasım' ? '11' : '' || authData.month == 'Aralık' ? '12' : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('odaRaporSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    yillikRapor({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'yillikRapor',
        yil: authData.year,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('yillikRaporSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    aylikRapor({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'aylikRapor',
        yil: authData.year,
        ay: authData.month === 'Ocak' ? '01' : '' || authData.month === 'Şubat' ? '02' : '' || authData.month === 'Mart' ? '03' : '' || authData.month === 'Nisan' ? '04' : '' || authData.month === 'Mayıs' ? '05' : '' || authData.month === 'Mayıs' ? '05' : '' || authData.month === 'Haziran' ? '06' : '' || authData.month === 'Temmuz' ? '07' : '' || authData.month === 'Ağustos' ? '08' : '' || authData.month === 'Eylül' ? '09' : '' || authData.month === 'Ekim' ? '10' : '' || authData.month === 'Kasım' ? '11' : '' || authData.month === 'Aralık' ? '12' : '',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('aylikRaporSave', res)
            commit('aylikTekilSave', res.tekilKonukSayilari)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    houseKeepingList({
      commit,
    }) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'housekeepingListesi',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('houseKeepingSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    checkOutList({
      commit,
    }) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'cikisBekleyenKonaklamalar',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('checkOutSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    rezervasyonListFiltre({
      commit,
    }, authData) {
      commit('clearRezervasyonList')
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'rezervasyonListesi',
        filtre: true,
        baslangic: authData.baslangic,
        bitis: authData.bitis,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('rezervasyonListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    rezervasyonList({
      commit,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'rezervasyonListesiSearch',
        searchKey: authData.searchKey || '',
        baslangic: authData.baslangic || 0,
        uzunluk: authData.uzunluk || 10,
        [authData.start != null ? 'start' : '']: authData.start != null ? authData.start : '',
        [authData.end != null ? 'end' : '']: authData.end != null ? authData.end : '',
        [authData.filtre != null ? 'filtre' : '']: authData.filtre != null || authData.filtre ? true : false,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('rezervasyonListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    konaklamaListFiltre({
      commit,
    }, authData) {
      commit('clearKonaklamaList')
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'konaklamaListesi',
        filtre: true,
        baslangic: authData.baslangic,
        bitis: authData.bitis,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('konaklamaListSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    bekleyenTah({
      commit,
    }) {
      const data = {
        crm_token: store.state.userToken,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
        serviceName: 'rapor',
        methodName: 'bekleyenTahsilatlar',
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('tahSave', res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
