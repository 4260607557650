export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/expariedLogin',
    name: 'expariedLogin',
    component: () => import('@/views/auth/expariedToken.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
