export default [
  {
    path: '/kurumKayit',
    name: 'kurumKayit',
    component: () => import('@/views/services/kurumKayit.vue'),
  }, {
    path: '/kurumUpdate/:id',
    name: 'kurumUpdate',
    component: () => import('@/views/services/kurumUpdate.vue'),
  }, {
    path: '/dokumantasyon',
    name: 'dokumantasyon',
    component: () => import('@/views/services/dokumantasyon.vue'),
  }, {
    path: '/userList',
    name: 'userList',
    component: () => import('@/views/services/userList.vue'),
  },
]
