export default [
  {
    path: '/konaklamaListesi',
    name: 'konaklamaListesi',
    component: () => import('@/views/rapor/konaklamaList.vue'),
  },
  {
    path: '/rezervasyonListesi',
    name: 'rezervasyonListesi',
    component: () => import('@/views/rapor/rezervasyonList.vue'),
  }, {
    path: '/onlineRezervasyonListesi',
    name: 'onlineRezervasyonListesi',
    component: () => import('@/views/rapor/onlineRezList.vue'),
  }, {
    path: '/aylikRapor',
    name: 'aylikRapor',
    component: () => import('@/views/rapor/aylikRapor.vue'),
  }, {
    path: '/yillikRapor',
    name: 'yillikRapor',
    component: () => import('@/views/rapor/yillikRapor.vue'),
  }, {
    path: '/odaRapor',
    name: 'odaRapor',
    component: () => import('@/views/rapor/odaRapor.vue'),
  }, {
    path: '/raporGunDetay',
    name: 'raporGunDetay',
    component: () => import('@/views/rapor/raporGunDetay.vue'),
  },
]
