import store from '@/store'
import router from '@/router'

export default {
  state: {
    kurumUserList: [],
  },
  mutations: {},
  getters: {
    kurumUserList: state => state.kurumUserList,
  },
  actions: {
    dokumantasyonPOST({
      commit,
      state,
      dispatch
    }, authData) {
      const data = {
        serviceName: authData.serviceName,
        methodName: authData.methodName,
        crm_token: authData.serviceName === 'oys' ? authData.token : store.state.userToken,
        firmaKodu: authData.firmaKodu,
        user: authData.user,
        pwd: authData.pwd,
        yil: authData.yil,
        ay: authData.ay,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    userPwdChange({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: localStorage.getItem('raporUserToken'),
        serviceName: 'otel',
        methodName: 'userList',
        userID: authData.userID,
        sifreDegis: authData.sifreDegis,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('setToken', res.userToken)
            resolve(res)
          },
          error(err) {
            if (err.status === 503) {
              router.push('/expariedLogin')
            }
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    userUpdate({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: localStorage.getItem('raporUserToken'),
        serviceName: 'otel',
        methodName: 'userList',
        level: authData.tur,
        isim: authData.isim,
        tel: authData.tel,
        ePosta: authData.ePosta,
        editUser: authData.userID,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('setToken', res.userToken)
            resolve(res)
          },
          error(err) {
            if (err.status === 503) {
              router.push('/expariedLogin')
            }
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    newUser({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: localStorage.getItem('raporUserToken'),
        serviceName: 'otel',
        methodName: 'userList',
        level: authData.tur,
        userName: authData.user,
        pwd: authData.pwd,
        isim: authData.isim,
        tel: authData.tel,
        ePosta: authData.ePosta,
        addUser: 1,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('setToken', res.userToken)
            resolve(res)
          },
          error(err) {
            if (err.status === 503) {
              router.push('/expariedLogin')
            }
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    userSil({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: localStorage.getItem('raporUserToken'),
        serviceName: 'otel',
        methodName: 'userList',
        delUser: authData.id,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('setToken', res.userToken)
            resolve(res)
          },
          error(err) {
            if (err.status === 503) {
              router.push('/expariedLogin')
            }
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    userStatusChange({
      commit,
      state
    }, authData) {
      const data = {
        crm_token: localStorage.getItem('raporUserToken'),
        serviceName: 'otel',
        methodName: 'userList',
        userPasif: authData.id,
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('setToken', res.userToken)
            resolve(res)
          },
          error(err) {
            if (err.status === 503) {
              router.push('/expariedLogin')
            }
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    userList({
      commit,
      state
    }) {
      const data = {
        crm_token: localStorage.getItem('raporUserToken'),
        serviceName: 'otel',
        methodName: 'userList',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('setToken', res.userToken)
          },
          error(err) {
            reject(err)
            if (err.status === 503) {
              router.push('/expariedLogin')
            }
          },
          dataType: 'json',
        })
      })
    },
    userList({
      state,
    }) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'kullaniciListesi',
        firmaKodu: localStorage.getItem('raporKurumKodu'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            state.kurumUserList = res.userList
            resolve(res.userList)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    deleteHosting({
      state,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'deleteHosting',
        hostUser: authData.hostUser,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    kurumRemove({
      state,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'firmaSil',
        silFirmaKodu: authData.firmaKodu,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    kurumUpdateSet({
      state,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'firmaDuzenle',
        edit: 1,
        firmaKodu: authData.firmaKodu,
        tamUnvan: authData.tamUnvan,
        unvan: authData.unvan,
        il: authData.il,
        ilce: authData.ilce,
        telefon: authData.telefon,
        ePosta: authData.ePosta,
        kurumYetkilisi: authData.kurumYetkilisi,
        yetkiliTelefon: authData.yetkiliTelefon,
        adres: authData.adres,
        siteURL: authData.siteURL,
        apiKey: authData.apiKey,
        CDN: authData.CDN,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    kurumUpdateGet({
      state,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'firmaDuzenle',
        firmaKodu: authData?.firmaKodu || router.app._route.params.id,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    kurumSave({
      state,
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'firmaKayit',
        save: 1,
        tamUnvan: authData.tamUnvan,
        unvan: authData.unvan,
        il: authData.il,
        ilce: authData.ilce,
        telefon: authData.telefon,
        ePosta: authData.ePosta,
        kurumYetkilisi: authData.kurumYetkilisi,
        yetkiliTelefon: authData.yetkiliTelefon,
        adres: authData.adres,
        siteURL: authData.siteURL,
        apiKey: authData.apiKey,
        CDN: authData.CDN,
        saveFirmaKodu: authData.saveFirmaKodu,
        userName: authData.user,
        pwd: authData.pwd,
      }
      if (authData.createHost) {
        data.createHost = authData.createHost === true ? 1 : 0
        data.domain = authData.domain
        data.hostUser = authData.userName
        data.userPass = authData.userPass
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
