import store from '@/store'

export default {
  state: {
    kurumListesi: [],
  },
  mutations: {
    kurumListAction(state, action) {
      state.kurumListesi = action
    },
  },
  getters: {
    getKurumList(state) {
      return state.kurumListesi
    },
  },
  actions: {
    dashIn({
      commit,
      state,
      dispatch
    }) {
      const data = {
        serviceName: 'dashBoard',
        crm_token: store.state.userToken,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            commit('kurumListAction', res.kurumListesi)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
