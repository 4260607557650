import jwt_decode from 'jwt-decode'
import router from '@/router'
import store from '@/store'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    sifreUpdate({
      commit,
      state,
      dispatch,
    }, authData) {
      const firmaKodu = localStorage.getItem('raporFirmaKodu')
      const userName = localStorage.getItem('raporUserName')
      const pwd = authData.pwd
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'firma',
        methodName: 'sifreDegis',
        pwd: authData.pwd,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            commit('setToken', res.userToken)
            resolve(res)
            dispatch('logIn', {
              firmaKodu,
              user: userName,
              pwd,
            })
          },
          error(err) {
            if (err.status === 503) {
              router.push('/expariedLogin')
            }
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    logIn({
      commit,
      state,
      dispatch
    }, authData) {
      const data = {
        serviceName: 'account',
        methodName: 'logIn',
        user: authData.user,
        pwd: authData.pwd,
        firmaKodu: 1,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            resolve(res)
            store.state.isAuth = true
            commit('setToken', res.userToken)
            commit('setUserData', jwt_decode(res.userToken))
            const today = new Date()
            const dd = String(today.getDate())
              .padStart(2, '0')
            const mm = String(today.getMonth() + 1)
              .padStart(2, '0')
            const yyyy = today.getFullYear()
            let todayy
            todayy = yyyy + '-' + mm + '-' + dd
            localStorage.setItem('raporCurrentDate', todayy)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
