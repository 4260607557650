import store from '@/store'

export default {
  state: {
    params: [],
    roomList: [],
    workDay: '',
  },
  mutations: {
    setWorkDay(state, tarih) {
      let month = tarih?.slice(5, 7)
      switch (month) {
        case '01':
          month = 'Ocak'
          break
        case '02':
          month = 'Şubat'
          break
        case '03':
          month = 'Mart'
          break
        case '04':
          month = 'Nisan'
          break
        case '05':
          month = 'Mayıs'
          break
        case '06':
          month = 'Haziran'
          break
        case '07':
          month = 'Temmuz'
          break
        case '08':
          month = 'Ağustos'
          break
        case '09':
          month = 'Eylül'
          break
        case '10':
          month = 'Ekim'
          break
        case '11':
          month = 'Kasım'
          break
        case '12':
          month = 'Aralık'
          break
        default:
          break
      }
      const gun = tarih?.slice(8, 10)
      const readyDate = gun + ' ' + month
      state.workDay = readyDate
    },
    kurumDetayAction(state, action) {
      action != 'remove'
        ? ((state.roomList = action.roomList), (state.params = action.params))
        : ((state.roomList = []), (state.params = []))
    },
  },
  getters: {
    getWorkDay(state) {
      if (state.workDay) {
        return state.workDay
      }
    },
    getKurumDetay(state) {
      return {
        params: state.params,
        roomList: state.roomList,
      }
    },
  },
  actions: {
    kurumDetay({
      commit,
      state,
      dispatch
    }, authData) {
      const data = {
        crm_token: store.state.userToken,
        serviceName: 'rapor',
        methodName: 'dashBoard',
        firmaKodu: authData,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            const today = new Date()
            const dd = String(today.getDate())
              .padStart(2, '0')
            const mm = String(today.getMonth() + 1)
              .padStart(2, '0')
            const yyyy = today.getFullYear()
            const date = [yyyy, mm, dd].join('-')
            localStorage.setItem('raporCurrentDate', date)
            commit('setWorkDay', date)
            resolve(res)
            commit('kurumDetayAction', res)
            commit('setToken', res.userToken)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
